import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/i18n/config';

import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MotionLazyContainer } from 'src/components/animate';

import { RootStore, RootStoreProvider, setupRootStore } from './models';
import router from './routes';
import ThemeProvider from './theme';

function App() {
  const [link, setLink] = useState('');
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    const l = window.location;

    // 카카오톡 인앱 브라우저 탈출
    if (/(?:iphone|ipad|android).* kakaotalk/i.test(ua)) {
      setLink(`kakaotalk://web/openExternal?url=${encodeURIComponent(l.href)}`);
    }
    // 라인 인앱 브라우저 탈출
    else if (/(?:iphone|ipad|android).* line\//i.test(ua)) {
      setLink(`${l.href}${/\?/.test(l.href) ? '&' : '?'}openExternalBrowser=1`);
    }
    // 기타 인앱 브라우저 탈출 (네이버, 인스타그램 등)
    else if (
      /inapp|naver|snapchat|instagram|everytimeapp|whatsapp|electron|wadiz|aliapp|zumapp|kakaostory|band|twitter|daumapps|fb_iab|fb4a|fban|fbios|fbss|trill/i.test(
        ua,
      )
    ) {
      if (/android/i.test(ua)) {
        // Android: Chrome으로 리다이렉트
        setLink(
          l.href.replace(
            /^(https?):\/\/(.*)$/,
            'intent://$2#Intent;scheme=$1;package=com.android.chrome;end',
          ),
        );
      } else if (/iphone|ipad/i.test(ua)) {
        // iOS: 크롬 브라우저 열기
        setLink(l.href.replace(/^http/, 'googlechrome'));
      }
    }
  }, []);

  useEffect(() => {
    // link가 설정되면 해당 링크로 이동
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  useEffect(() => {
    // setup root store
    setupRootStore().then((store) => {
      setRootStore(store);
    });
  }, []);

  if (!rootStore) {
    return <div>Loading...</div>;
  }

  return (
    <RootStoreProvider value={rootStore}>
      <HelmetProvider>
        <ThemeProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MotionLazyContainer>
              <RouterProvider
                router={router}
                future={{
                  v7_startTransition: true,
                }}
              />
            </MotionLazyContainer>
          </LocalizationProvider>
        </ThemeProvider>
      </HelmetProvider>
    </RootStoreProvider>
  );
}

export default App;
