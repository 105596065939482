import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const EditIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.99998 15.0001H15.75M2.25 15H3.50591C3.87279 15 4.05624 15 4.22887 14.9586C4.38192 14.9219 4.52824 14.8613 4.66245 14.779C4.81382 14.6862 4.94354 14.5565 5.20296 14.2971L14.625 4.87505C15.2463 4.25373 15.2463 3.24637 14.625 2.62505C14.0037 2.00373 12.9963 2.00373 12.375 2.62505L2.95295 12.0471C2.69352 12.3065 2.5638 12.4362 2.47104 12.5876C2.3888 12.7218 2.32819 12.8681 2.29145 13.0212C2.25 13.1938 2.25 13.3773 2.25 13.7442V15Z"
      stroke="#AAAAAA"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
