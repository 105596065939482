import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; // useTranslation import 추가
import { useOutletContext } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { ISearchParams, TPagination } from 'src/@types';
import { IOrderInquiry } from 'src/@types/apiResponse';
import { EditIcon } from 'src/assets/icons';
import MessageDotsSquareIcon from 'src/assets/icons/MessageDotsSquareIcon';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGINATION } from 'src/assets/static/constants';
import EmptyContent from 'src/components/EmptyContent';
import OrderInquiryFormDialog, {
  OrderInquiryFormDialogProps,
} from 'src/components/goods/OrderInquiryFormDialog';
import InfiniteScrollObserver from 'src/components/InfiniteScrollObserver';
import { Page } from 'src/components/Page';
import { LayoutProps } from 'src/layouts';
import { useStores } from 'src/models';
import { orderApi } from 'src/services';

function MyOrderInquiry() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { alertStore } = useStores();
  const { setHeaderProps } = useOutletContext<LayoutProps>();
  const [myOrderInquiryList, setMyOrderInquiryList] = useState<IOrderInquiry[]>([]);
  const [orderInquiryFormDialogProps, setOrderInquiryFormDialogProps] =
    useState<OrderInquiryFormDialogProps>({
      open: false,
    });

  // Pagination
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<ISearchParams>({
    page: 1,
    size: DEFAULT_PAGE_SIZE,
  });
  const [pagination, setPagination] = useState<TPagination>(DEFAULT_PAGINATION);

  // Get my goods qna list
  const getMyOrderInquiryList = useCallback(async () => {
    try {
      setIsPageLoading(true);
      // api 호출
      const result = await orderApi.getOrderInquiries();

      const data = result?.data;
      if (data) {
        searchParams.page === 1
          ? setMyOrderInquiryList(data)
          : setMyOrderInquiryList((prev) => [...prev, ...data]);
        if (result.pagination) {
          setPagination(result.pagination);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsPageLoading(false);
    }
  }, [searchParams]);

  // set header
  useEffect(() => {
    setHeaderProps({
      leftTypes: ['back'],
      centerTitle: t('orderInquiry.pageTitle'), // Q&A 내역 제목 번역 적용
      rightTypes: ['cart'],
    });

    return () => setHeaderProps({});
  }, [setHeaderProps, t]);

  useEffect(() => {
    getMyOrderInquiryList();
  }, [getMyOrderInquiryList, searchParams]);

  // 상품문의 수정 버튼 클릭 시
  const handleClickEditOrderInquiry = async (orderNo: string, orderInquirySid: number) => {
    try {
      // get goods qna info
      const result = await orderApi.getOrderInquiry(orderNo, orderInquirySid);

      if (result?.data) {
        // open form modal
        setOrderInquiryFormDialogProps({
          open: true,
          onSuccess: getMyOrderInquiryList,
          inquirySid: result.data.orderInquirySid,
          orderNo: result.data.orderNo,
          formData: {
            inquiryNm: result.data.inquiryNm,
            inquiryConts: result.data.inquiryConts,
            attachImgPathList: result.data.attachImgPathList,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // page load more
  const handleLoadMore = () => {
    const nextPage = searchParams.page + 1;
    setSearchParams((prev) => ({ ...prev, page: nextPage }));
  };

  return (
    <StyledPage needPadding={false} title={t('orderInquiry.pageTitle')}>
      <Item>
        {myOrderInquiryList.length > 0 ? (
          <>
            {myOrderInquiryList.map((inquiry: IOrderInquiry, i: number) => (
              <StyledAccordion key={'inquiry-' + inquiry.orderInquirySid + i}>
                <AccordionSummary expandIcon={<ExpandMore color="disabled" />}>
                  <Stack>
                    <Stack gap={1.5}>
                      <Stack flexDirection="row" gap={1} alignContent="center">
                        <Typography
                          color={theme.palette.primary.main}
                          fontSize={14}
                          lineHeight="100%"
                          fontWeight={600}
                        >
                          주문번호
                        </Typography>
                        <Typography
                          color={theme.palette.primary.main}
                          fontSize={14}
                          lineHeight="100%"
                          fontWeight={500}
                        >
                          {inquiry.orderNo}
                        </Typography>
                      </Stack>
                      <Typography fontSize={16} lineHeight="100%" fontWeight={400}>
                        {inquiry.inquiryNm}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} gap={1.5}>
                      {inquiry.inquiryAnswerTypeCd && (
                        <Typography
                          fontSize={13}
                          sx={{
                            wordBreak: 'keep-all',

                            color:
                              inquiry.inquiryAnswerTypeCd.code === 850001
                                ? theme.palette.error.main
                                : theme.palette.text.primary,
                          }}
                        >
                          [{inquiry.inquiryAnswerTypeCd.value}]
                        </Typography>
                      )}
                      <Typography
                        fontSize={13}
                        fontWeight={500}
                        color={theme.palette.grey[400]}
                        sx={{ wordBreak: 'keep-all' }}
                      >
                        {format(inquiry.regDt, 'yyyy-MM-dd')}
                      </Typography>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack gap={2}>
                    <Typography
                      variant="body2"
                      color={theme.palette.text.primary}
                      sx={{ wordBreak: 'break-word' }}
                    >
                      {inquiry.inquiryConts}
                    </Typography>
                    {inquiry.inquiryAnswerTypeCd &&
                      inquiry.inquiryAnswerTypeCd.code === 850002 && ( // 답변 대기중일때만 수정, 삭제가능
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
                          <IconButton
                            onClick={() =>
                              handleClickEditOrderInquiry(inquiry.orderNo, inquiry.orderInquirySid)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Stack>
                      )}
                  </Stack>
                  {inquiry?.answerDt && (
                    <Stack gap={2}>
                      <Divider
                        sx={{ my: 4, borderStyle: 'dashed', borderColor: theme.palette.grey[300] }}
                      />
                      <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Avatar />
                        <Typography variant="subtitle2">
                          {t('myPage.csCenter.inquiries.operator')}
                        </Typography>
                        <Typography variant="caption">
                          {format(inquiry?.answerDt, 'yyyy.MM.dd hh:mm')}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          p: 2,
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="body2" sx={{ wordBreak: 'keep-all' }}>
                          {inquiry.answerConts}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </AccordionDetails>
              </StyledAccordion>
            ))}
            <InfiniteScrollObserver
              isLast={pagination.last}
              loadMore={handleLoadMore}
              isLoading={isPageLoading}
            />
          </>
        ) : (
          <EmptyContent
            style={{ padding: '20px 0' }}
            icon={() => <MessageDotsSquareIcon />}
            description={t('orderInquiry.noInquiries')}
          />
        )}
      </Item>
      {/* Order Inquiry Form */}
      {orderInquiryFormDialogProps.open && (
        <OrderInquiryFormDialog
          {...orderInquiryFormDialogProps}
          onClose={() => setOrderInquiryFormDialogProps({ open: false })}
        />
      )}
    </StyledPage>
  );
}

export default MyOrderInquiry;

const StyledPage = styled(Page)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  backgroundColor: theme.palette.grey[100],
}));

const Item = styled(Stack)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(5),
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '&.Mui-expanded': {
    margin: 0,
    boxShadow: 'none',
  },
}));
