import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
// material
import { Box, BoxProps } from '@mui/material';
import { PAGE_PADDING } from 'src/assets/static/constants';

import CommonAlert from './alert/CommonAlert';
import CommonSnackbar from './alert/CommonSnackbar';

interface PageProps extends BoxProps {
  title?: string;
  needPadding?: boolean;
}

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = '', needPadding = true, sx, ...others }, ref) => (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        {...others}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '100%',
          ...(needPadding && { p: `${PAGE_PADDING}px` }),
          ...sx,
        }}
      >
        {children}
      </Box>
      {/* TODO 나중에 위치 바꾸자 */}
      <CommonAlert />
      <CommonSnackbar />
    </React.Fragment>
  ),
);
