import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {}

const XCircleIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
      {...props}
    >
      <circle cx="25" cy="25" r="25" fill="#000" />
      <path
        fill="#fff"
        d="M31.892 14.156h3.662l-8 9.205L37 35.843h-7.422l-5.783-7.566-6.65 7.566h-3.663l8.578-9.831L13 14.156h7.615l5.253 6.94zm-1.302 19.47h2.024L19.507 16.23H17.29z"
        clipPath="url(#a)"
      />
    </svg>
  );
};

export default XCircleIcon;
