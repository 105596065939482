import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const MessageDotsSquareIcon = (props: Props) => (
  <svg
    width={71}
    height={71}
    viewBox="0 0 71 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.375 31.417H22.4042M35.5 31.417H35.5292M48.625 31.417H48.6542M20.9167 53.292V60.1039C20.9167 61.658 20.9167 62.4351 21.2353 62.8342C21.5123 63.1813 21.9325 63.3832 22.3766 63.3827C22.8872 63.3822 23.494 62.8968 24.7076 61.9259L31.6652 56.3598C33.0865 55.2228 33.7972 54.6543 34.5885 54.25C35.2906 53.8913 36.0379 53.6291 36.8102 53.4706C37.6807 53.292 38.5908 53.292 40.4109 53.292H47.75C52.6505 53.292 55.1007 53.292 56.9724 52.3383C58.6188 51.4994 59.9574 50.1608 60.7963 48.5144C61.75 46.6427 61.75 44.1924 61.75 39.292V23.542C61.75 18.6415 61.75 16.1913 60.7963 14.3196C59.9574 12.6732 58.6188 11.3346 56.9724 10.4957C55.1007 9.54199 52.6505 9.54199 47.75 9.54199H23.25C18.3495 9.54199 15.8993 9.54199 14.0276 10.4957C12.3812 11.3346 11.0426 12.6732 10.2037 14.3196C9.25 16.1913 9.25 18.6415 9.25 23.542V41.6253C9.25 44.3377 9.25 45.6939 9.54815 46.8067C10.3572 49.8262 12.7158 52.1848 15.7353 52.9938C16.848 53.292 18.2042 53.292 20.9167 53.292ZM23.8333 31.417C23.8333 32.2224 23.1804 32.8753 22.375 32.8753C21.5696 32.8753 20.9167 32.2224 20.9167 31.417C20.9167 30.6116 21.5696 29.9587 22.375 29.9587C23.1804 29.9587 23.8333 30.6116 23.8333 31.417ZM36.9583 31.417C36.9583 32.2224 36.3054 32.8753 35.5 32.8753C34.6946 32.8753 34.0417 32.2224 34.0417 31.417C34.0417 30.6116 34.6946 29.9587 35.5 29.9587C36.3054 29.9587 36.9583 30.6116 36.9583 31.417ZM50.0833 31.417C50.0833 32.2224 49.4304 32.8753 48.625 32.8753C47.8196 32.8753 47.1667 32.2224 47.1667 31.417C47.1667 30.6116 47.8196 29.9587 48.625 29.9587C49.4304 29.9587 50.0833 30.6116 50.0833 31.417Z"
      stroke="#DDDDDD"
      strokeWidth={2.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MessageDotsSquareIcon;
