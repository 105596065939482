import React, { useEffect, useRef } from 'react';
import { Box, CircularProgress, styled } from '@mui/material';

interface InfiniteScrollObserverProps {
  loadMore: () => void; // Function to load more data when the observer is triggered
  isLast: boolean;
  isLoading: boolean; // A flag to show if data is currently being loaded
  threshold?: number; // Intersection threshold (defaults to 1.0)
  rootMargin?: string; // Margin around the root (defaults to '0px')
}

function InfiniteScrollObserver({
  loadMore,
  isLast,
  isLoading,
  threshold = 1.0,
  rootMargin = '0px',
}: InfiniteScrollObserverProps) {
  const observerRef = useRef<HTMLDivElement | null>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isLoading || isLast) return; // Do nothing if already loading or no more data

    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading && !isLast) {
          loadMore();
        }
      },
      {
        root: observerRef.current,
        rootMargin,
        threshold,
      },
    );

    const target = loadMoreRef.current;
    if (target) observer.observe(target);

    return () => {
      if (target) observer.unobserve(target);
    };
  }, [isLoading, isLast, loadMore, threshold, rootMargin]);

  return (
    <>
      {/* Content would be rendered above this */}
      <div ref={loadMoreRef} style={{ height: '1px' }} />
      {isLoading && (
        <StyledBox>
          <CircularProgress size={20} />
        </StyledBox>
      )}
      {isLast && <></>}
    </>
  );
}

export default InfiniteScrollObserver;

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
}));
