import { CSSProperties, ReactNode } from 'react';
// form
import { FormProvider as ReactHookFormProvider, UseFormReturn } from 'react-hook-form';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  isLoginForm?: boolean;
  sx?: CSSProperties;
  formId?: string;
};

export default function FormProvider({
  children,
  onSubmit,
  methods,
  isLoginForm,
  sx,
  formId,
  ...props
}: Props) {
  const { REACT_APP_API_URL } = process.env;
  return (
    <ReactHookFormProvider {...methods}>
      {isLoginForm ? (
        <form action={`${REACT_APP_API_URL}/loginProcess`} method="POST" {...props}>
          {children}
        </form>
      ) : (
        <form onSubmit={onSubmit} style={sx} id={formId} {...props}>
          {children}
        </form>
      )}
    </ReactHookFormProvider>
  );
}
