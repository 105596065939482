// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
};

export default function RHFTextField({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();
  const theme = useTheme();

  const { sx, ...others } = other;
  const defaultSx = {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.neutral,
    },
    '& fieldset': {
      border: 'none',
    },
    '& .Mui-focused': {
      '& fieldset': {
        border: `1px solid ${theme.palette.common.black}`,
      },
      '&.MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 6px 8px 0px #0000000F',
      },
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...others}
          inputRef={other.inputRef}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          error={!!error}
          helperText={error ? error?.message : helperText}
          sx={other.variant === undefined ? { ...defaultSx, ...sx } : { ...sx }}
          onChange={(e) => {
            field.onChange(e); // react-hook-form 상태 업데이트
            if (other.onChange) {
              other.onChange(e); // 추가적인 onChange 핸들러 호출
            }
          }}
        />
      )}
    />
  );
}
