import Slider, { Settings } from 'react-slick';
import { DialogProps, IconButton, styled } from '@mui/material';
import { ArrowRightIcon, CloseIcon } from 'src/assets/icons';
import Image from 'src/components/image';

import PopperDialog from './PopperDialog';

export interface ImageSlideDialogProps extends DialogProps {
  onClose?: () => void;
  imageList?: string[];
  initialSlide?: number;
}

/**
 * ## ImageSlideDialog 설명
 * - 이미지 슬라이드 모달 창을 띄울 수 있습니다.
 */
const ImageSlideDialog = ({ imageList, initialSlide, ...props }: ImageSlideDialogProps) => {
  // image list가 하나만 있는 경우, 두 번 반복해서 이미지 슬라이드를 구성한다.
  const images = imageList
    ? [...(imageList.length === 1 ? [imageList[0], imageList[0]] : imageList)]
    : [];

  const settings: Settings = {
    speed: 300,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: true,
  };

  return (
    <PopperDialog
      enableBackdropClickClose
      PaperProps={{ sx: { justifyContent: 'center' } }}
      {...props}
    >
      <CloseButton onClick={props.onClose}>
        <CloseIcon color="white" />
      </CloseButton>
      <div className="slider-container">
        <Slider {...settings} initialSlide={initialSlide}>
          {images?.map((src: string, index) => {
            return (
              <Image
                src={src.includes('http') ? src : process.env.REACT_APP_API_URL + src}
                key={`img-${index}`}
                sx={{ width: '100%' }}
              />
            );
          })}
        </Slider>
      </div>
    </PopperDialog>
  );
};

export default ImageSlideDialog;

const PrevArrow = styled(ArrowRightIcon)(({ theme }) => ({
  left: `0 !important`,
  zIndex: 2,
  width: `30px !important`,
  height: `30px !important`,
  transform: `translate(0, -50%) rotate(180deg) !important`,

  '& path': {
    fill: theme.palette.common.white,
  },
}));

const NextArrow = styled(ArrowRightIcon)(({ theme }) => ({
  right: `0 !important`,
  zIndex: 3,
  width: `30px !important`,
  height: `30px !important`,
  '& path': {
    fill: theme.palette.common.white,
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(4),
  right: theme.spacing(4),
}));
