import { useTranslation } from 'react-i18next';
import { alpha, styled } from '@mui/material';
import { IOrderGoods } from 'src/@types/apiResponse';
import {
  CANCEL_STATE_CODE,
  EXCHANGE_STATE_CODE,
  ORDER_STATE_CODE,
} from 'src/assets/static/constants';

interface OrderStatusLabelProps {
  goods: IOrderGoods;
}

function OrderStatusLabel({ goods }: OrderStatusLabelProps) {
  const { t } = useTranslation();
  let label = '';
  let isCompleted = false;
  let isFailed = false;

  switch (goods.orderStateCd.code) {
    case ORDER_STATE_CODE.ORDER_COMPLETED:
      label = t('orderStatus.labels.orderCompleted');
      break;
    case ORDER_STATE_CODE.PAYMENT_WAITING:
      label = t('orderStatus.labels.paymentWaiting');
      break;
    case ORDER_STATE_CODE.PAYMENT_COMPLETED:
      label = t('orderStatus.labels.paymentCompleted');
      break;
    case ORDER_STATE_CODE.DELIVERY_PREPARING:
      label = t('orderStatus.labels.deliveryPreparing');
      break;
    case ORDER_STATE_CODE.DELIVERING:
      label = t('orderStatus.labels.delivering');
      break;
    case ORDER_STATE_CODE.DELIVERY_COMPLETED:
      label = t('orderStatus.labels.deliveryCompleted');
      isCompleted = true;
      break;
    case ORDER_STATE_CODE.RETURN_REQUEST:
      label = t('orderStatus.labels.returnRequest');
      break;
    case ORDER_STATE_CODE.RETURNING:
      label = t('orderStatus.labels.returning');
      break;
    case ORDER_STATE_CODE.RETURN_COMPLETED:
      label = t('orderStatus.labels.returnCompleted');
      break;
    case ORDER_STATE_CODE.ANALYSIS_REQUESTED:
      label = t('orderStatus.labels.analysisRequested');
      break;
    case ORDER_STATE_CODE.ANALYZING:
      label = t('orderStatus.labels.analyzing');
      break;
    case ORDER_STATE_CODE.ANALYSIS_COMPLETED:
      label = t('orderStatus.labels.analysisCompleted');
      isCompleted = true;
      break;
    case ORDER_STATE_CODE.ANALYSIS_FAILED:
      label = t('orderStatus.labels.analysisFailed');
      isFailed = true;
      break;
    case ORDER_STATE_CODE.ORDER_CANCEL_COMPLETED:
      label = t('orderStatus.labels.orderCancelCompleted');
      break;
    case ORDER_STATE_CODE.GOODS_PREPARING:
      label = t('orderStatus.labels.goodsPreparing');
      break;
    case ORDER_STATE_CODE.ANALYSIS_IMPOSSIBLE:
      label = t('orderStatus.labels.analysisImpossible');
      break;
    case ORDER_STATE_CODE.DELIVERY_FAILED:
      label = t('orderStatus.labels.deliveryFailed');
      break;
  }

  // 반품 요청 시 상태 변경
  // 주문상태와 취소상태에 따라 반품 상태 변경
  switch (goods.cancelStateCd.code) {
    case CANCEL_STATE_CODE.REQUESTED:
      label = t('orderStatus.labels.cancelRequested');
      switch (goods.orderStateCd.code) {
        case ORDER_STATE_CODE.RETURN_REQUEST:
        case ORDER_STATE_CODE.RETURNING:
          label = t('orderStatus.labels.returning');
          break;
        case ORDER_STATE_CODE.RETURN_COMPLETED:
          label = t('orderStatus.labels.returnCompleted');
          break;
      }
      break;
    case CANCEL_STATE_CODE.APPROVED:
      label = t('orderStatus.labels.cancelApproved');
      isCompleted = true;
      break;
    case CANCEL_STATE_CODE.REJECTED:
      label = t('orderStatus.labels.cancelRejected');
      isFailed = true;
      break;
  }

  // 교환 요청 시 상태 변경
  // 주문상태와 교환상태에 따라 교환 상태 변경
  switch (goods.exchangeStateCd.code) {
    case EXCHANGE_STATE_CODE.REQUESTED:
      label = t('orderStatus.labels.exchangeRequested');
      switch (goods.orderStateCd.code) {
        case ORDER_STATE_CODE.RETURN_REQUEST:
        case ORDER_STATE_CODE.RETURNING:
          label = t('orderStatus.labels.returning');
          break;
        case ORDER_STATE_CODE.RETURN_COMPLETED:
          label = t('orderStatus.labels.returnCompleted');
          break;
      }
      break;
    case EXCHANGE_STATE_CODE.APPROVED:
      label = t('orderStatus.labels.exchangeApproved');
      switch (goods.orderStateCd.code) {
        // 교환 준비중
        case ORDER_STATE_CODE.DELIVERY_PREPARING:
        case ORDER_STATE_CODE.GOODS_PREPARING:
          label = t('orderStatus.labels.exchangePreparing');
          break;
        // 교환 중
        case ORDER_STATE_CODE.DELIVERING:
          label = t('orderStatus.labels.exchanging');
          break;
        // 교환 완료
        case ORDER_STATE_CODE.DELIVERY_COMPLETED:
          label = t('orderStatus.labels.exchangeApproved');
          break;
      }
      break;
    case EXCHANGE_STATE_CODE.REJECTED:
      label = t('orderStatus.labels.exchangeRejected');
      isFailed = true;
      break;
  }

  return (
    <Label isCompleted={isCompleted} isFailed={isFailed}>
      {label}
    </Label>
  );
}

export default OrderStatusLabel;

const Label = styled('span', {
  shouldForwardProp: (prop) => prop !== 'isCompleted' && prop !== 'isFailed',
})<{ isCompleted?: boolean; isFailed?: boolean }>(({ theme, isCompleted, isFailed }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.grey[100],
  fontSize: 13,
  lineHeight: '100%',
  fontWeight: 600,
  color: theme.palette.grey[500],
  marginBottom: theme.spacing(1),

  ...(isCompleted && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  }),

  ...(isFailed && {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.1),
  }),
}));
