import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Dialog, DialogProps, Fade } from '@mui/material';
import { LayoutProps } from 'src/layouts';

interface PopperDialogProps extends DialogProps {
  onClose?: () => void;
  enableBackdropClickClose?: boolean;
}

/**
 * ## PopperDialog 설명
 * - mobileContainer 위치에 따라 모달 창을 띄울 수 있습니다.
 */
const PopperDialog = ({
  open,
  children,
  onClose,
  sx,
  PaperProps,
  enableBackdropClickClose,
  ...props
}: PopperDialogProps) => {
  const { mobileContainer } = useOutletContext<LayoutProps>();
  const [dialogLeft, setDialogLeft] = useState(mobileContainer?.getBoundingClientRect().left);

  const handleResize = useCallback(() => {
    // resize 이벤트 발생 시 dialogLeft 다시 계산한다.
    setDialogLeft(mobileContainer?.getBoundingClientRect().left);
  }, [mobileContainer]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <Dialog
      fullWidth
      hideBackdrop
      TransitionComponent={Fade}
      transitionDuration={{ enter: 200, exit: 200 }}
      closeAfterTransition
      open={open}
      onClose={(e, reason) => {
        if ((!enableBackdropClickClose && reason === 'backdropClick') || reason === 'escapeKeyDown')
          return;
        onClose && onClose();
      }}
      sx={{
        margin: '0 !important',
        padding: '0 !important',
        ...sx,
      }}
      PaperProps={{
        ...PaperProps,
        // backdrop click close
        onClick: (e: MouseEvent) => {
          if (!enableBackdropClickClose) return;
          if (e.target === e.currentTarget) {
            onClose && onClose();
            e.stopPropagation();
          }
        },
        style: {
          position: 'absolute',
          padding: 0,
          margin: 0,
          borderRadius: 0,
          boxShadow: 'none',
          left: dialogLeft,
          width: mobileContainer?.clientWidth || '100%',
          minWidth: mobileContainer?.clientWidth || '100%',
          minHeight: '100%',
          // like backdrop
          backgroundColor: 'rgba(32, 33, 35, 0.8)',
          ...PaperProps?.style,
        },
      }}
      {...props}
    >
      {children}
    </Dialog>
  );
};

export default PopperDialog;
