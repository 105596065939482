import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const SearchRefractionIcon = (props: Props) => (
  <svg
    width={71}
    height={70}
    viewBox="0 0 71 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M61.75 61.25L49.0625 48.5625M32.5833 17.5C40.6375 17.5 47.1667 24.0292 47.1667 32.0833M55.9167 32.0833C55.9167 44.97 45.47 55.4167 32.5833 55.4167C19.6967 55.4167 9.25 44.97 9.25 32.0833C9.25 19.1967 19.6967 8.75 32.5833 8.75C45.47 8.75 55.9167 19.1967 55.9167 32.0833Z"
      stroke="#DDDDDD"
      strokeWidth={2.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchRefractionIcon;
