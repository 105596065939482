import { SVGProps } from 'react';
import { useTheme } from '@mui/material';

interface Props extends SVGProps<SVGSVGElement> {
  checked?: boolean;
  disabled?: boolean;
}

const CheckCircleIcon = ({ checked, disabled, ...props }: Props) => {
  const theme = useTheme();
  const getFillColor = () => {
    if (disabled) {
      return theme.palette.grey[200];
    } else if (checked) {
      return theme.palette.common.black;
    } else {
      return theme.palette.grey[400];
    }
  };
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_410_7162)">
        <g>
          <mask
            id="mask0_410_7162"
            style={{
              maskType: 'luminance',
            }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={16}
            height={16}
          >
            <g>
              <g>
                <path
                  d="M7.99968 14.6667C8.87532 14.6678 9.74256 14.4959 10.5515 14.1608C11.3605 13.8257 12.0953 13.334 12.7137 12.714C13.3337 12.0957 13.8253 11.3609 14.1605 10.5519C14.4956 9.74293 14.6675 8.87569 14.6663 8.00005C14.6675 7.1244 14.4955 6.25717 14.1604 5.44819C13.8253 4.6392 13.3337 3.90441 12.7137 3.28605C12.0953 2.66606 11.3605 2.17438 10.5515 1.83927C9.74256 1.50417 8.87532 1.33225 7.99968 1.33338C7.12404 1.33226 6.25681 1.5042 5.44782 1.8393C4.63884 2.1744 3.90405 2.66607 3.28568 3.28605C2.6657 3.90441 2.17404 4.6392 1.83893 5.44819C1.50383 6.25717 1.3319 7.1244 1.33301 8.00005C1.33188 8.87569 1.5038 9.74293 1.83891 10.5519C2.17401 11.3609 2.66569 12.0957 3.28568 12.714C3.90405 13.334 4.63884 13.8257 5.44782 14.1608C6.25681 14.4959 7.12404 14.6678 7.99968 14.6667Z"
                  fill="white"
                  stroke="white"
                  strokeWidth={1.2}
                  strokeLinejoin="round"
                />
                <path
                  d="M5.33301 8L7.33301 10L11.333 6"
                  stroke="black"
                  strokeWidth={1.2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </mask>
          <g mask="url(#mask0_410_7162)">
            <path d="M0 0H16V16H0V0Z" fill={getFillColor()} />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_410_7162">
          <rect width={16} height={16} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CheckCircleIcon;
