import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const AlertCircleIcon = (props: Props) => (
  <svg
    width={51}
    height={50}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.4994 16.6666V28.75M25.4994 33.3333H25.5202M46.3327 25C46.3327 36.5059 37.0053 45.8333 25.4994 45.8333C13.9934 45.8333 4.66602 36.5059 4.66602 25C4.66602 13.494 13.9934 4.16663 25.4994 4.16663C37.0053 4.16663 46.3327 13.494 46.3327 25Z"
      stroke="#DDDDDD"
      strokeWidth={2.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AlertCircleIcon;
