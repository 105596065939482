import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
}

const ArrowLeftIcon = ({ color = '#AAAAAA', ...props }: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 15.875L11.12 11.995L15 8.11498C15.39 7.72498 15.39 7.09498 15 6.70498C14.61 6.31498 13.98 6.31498 13.59 6.70498L9 11.295C8.61 11.685 8.61 12.315 9 12.705L13.59 17.295C13.98 17.685 14.61 17.685 15 17.295C15.38 16.905 15.39 16.265 15 15.875Z"
      fill={color}
    />
  </svg>
);

export default ArrowLeftIcon;
