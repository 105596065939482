import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Portal, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { LayoutProps } from 'src/layouts';
import { useStores } from 'src/models';

function CommonSnackbar() {
  const { snackbarStore } = useStores();
  const { open, btnLabel, title, category, invokeOnClick, closeSnackbar } = snackbarStore;
  const { mobileContainer } = useOutletContext<LayoutProps>();

  const handleOnClick = () => {
    invokeOnClick();
    closeSnackbar();
  };

  return (
    <Portal>
      <Snackbar
        style={{
          left: mobileContainer?.getBoundingClientRect().left,
          width: mobileContainer?.getBoundingClientRect().width,
          bottom: 200,
          display: 'flex',
          padding: '0 16px',
          justifyContent: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            flexGrow: 0,
            maxWidth: '100%',
            '& .MuiSnackbarContent-message': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          },
        }}
        open={open}
        onClose={closeSnackbar}
        autoHideDuration={3000}
        onClick={(e) => {
          // content 클릭 시 닫히지 않도록
          const target = e.target as HTMLElement;
          if (
            target.className.includes('MuiSnackbarContent-message') ||
            target.className.includes('MuiSnackbarContent-root')
          ) {
            return;
          }
          closeSnackbar();
        }}
        message={title}
        action={
          btnLabel ? (
            <Button size="small" variant="contained" onClick={handleOnClick} color={category}>
              {btnLabel}
            </Button>
          ) : null
        }
      />
    </Portal>
  );
}

export default observer(CommonSnackbar);
