import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const ShoppingBagIcon = (props: Props) => (
  <svg
    width={71}
    height={70}
    viewBox="0 0 71 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6 7.69992L12.05 13.7666C11.1488 14.9682 10.6982 15.569 10.7086 16.0719C10.7177 16.5095 10.923 16.9199 11.2676 17.1898C11.6637 17.4999 12.4147 17.4999 13.9167 17.4999H57.0833C58.5853 17.4999 59.3363 17.4999 59.7324 17.1898C60.0771 16.9199 60.2823 16.5095 60.2914 16.0719C60.3018 15.569 59.8512 14.9682 58.95 13.7666L54.4 7.69992M16.6 7.69992C17.1133 7.01548 17.37 6.67326 17.6953 6.42643C17.9834 6.20782 18.3096 6.0447 18.6574 5.94538C19.05 5.83325 19.4778 5.83325 20.3333 5.83325H50.6667C51.5222 5.83325 51.95 5.83325 52.3426 5.94538C52.6904 6.0447 53.0166 6.20782 53.3047 6.42643C53.63 6.67326 53.8867 7.01548 54.4 7.69992M16.6 7.69992L11.1167 15.011C10.4241 15.9345 10.0778 16.3962 9.83187 16.9047C9.61367 17.3559 9.45452 17.8333 9.35836 18.3252C9.25 18.8795 9.25 19.4567 9.25 20.611L9.25 54.8332C9.25 58.1002 9.25 59.7337 9.8858 60.9815C10.4451 62.0791 11.3374 62.9715 12.4351 63.5308C13.6829 64.1666 15.3164 64.1666 18.5833 64.1666L52.4167 64.1666C55.6836 64.1666 57.3171 64.1666 58.5649 63.5308C59.6626 62.9715 60.5549 62.0791 61.1142 60.9815C61.75 59.7337 61.75 58.1002 61.75 54.8332V20.611C61.75 19.4567 61.75 18.8795 61.6416 18.3252C61.5455 17.8333 61.3863 17.3559 61.1681 16.9047C60.9222 16.3962 60.5759 15.9345 59.8833 15.011L54.4 7.69992M47.1667 29.1666C47.1667 32.2608 45.9375 35.2282 43.7496 37.4162C41.5617 39.6041 38.5942 40.8332 35.5 40.8332C32.4058 40.8332 29.4383 39.6041 27.2504 37.4162C25.0625 35.2282 23.8333 32.2608 23.8333 29.1666"
      stroke="#DDDDDD"
      strokeWidth={2.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShoppingBagIcon;
