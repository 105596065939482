import React from 'react';
import { Button, ButtonProps, Stack, styled, Typography, useTheme } from '@mui/material';

interface EmptyContentProps extends React.HTMLAttributes<HTMLDivElement> {
  icon: () => React.ReactNode;
  description?: string;
  buttonLabel?: string;
  onClick?: () => void;
}

function EmptyContent({ icon, description, buttonLabel, onClick, ...props }: EmptyContentProps) {
  const theme = useTheme();
  const lines = description?.split('<br/>');

  return (
    <Container {...props}>
      <Stack flexDirection="column" justifyContent="center" alignItems="center" gap={5}>
        {icon()}
        {description && (
          <Typography
            fontSize={16}
            lineHeight="150%"
            textAlign="center"
            color={theme.palette.grey[400]}
            whiteSpace={'pre-line'}
          >
            {lines?.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index < lines.length - 1 && <br />}
              </React.Fragment>
            ))}
          </Typography>
        )}
      </Stack>
      {buttonLabel && (
        <StyledButton size="medium" onClick={onClick}>
          {buttonLabel}
        </StyledButton>
      )}
    </Container>
  );
}

export default EmptyContent;

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(7.5),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 180,
  height: 50,
  border: `1px solid ${theme.palette.grey[300]}`,
})) as typeof Button & { defaultProps: Partial<ButtonProps> };

StyledButton.defaultProps = {
  variant: 'outlined',
  color: 'secondary',
};
