import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const MessageIcon = (props: Props) => (
  <svg
    width={19}
    height={18}
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.375 9H6.3825M9.75 9H9.7575M13.125 9H13.1325M9.75 15.75C13.4779 15.75 16.5 12.7279 16.5 9C16.5 5.27208 13.4779 2.25 9.75 2.25C6.02208 2.25 3 5.27208 3 9C3 9.89786 3.1753 10.7548 3.49355 11.5384C3.55446 11.6884 3.58491 11.7633 3.5985 11.824C3.61179 11.8833 3.61665 11.9271 3.61666 11.9879C3.61666 12.05 3.60539 12.1177 3.58283 12.253L3.1381 14.9214C3.09152 15.2009 3.06824 15.3406 3.11157 15.4416C3.1495 15.53 3.21996 15.6005 3.30839 15.6384C3.40942 15.6818 3.54914 15.6585 3.82857 15.6119L6.49699 15.1672C6.63231 15.1446 6.69998 15.1333 6.7621 15.1333C6.82286 15.1334 6.86675 15.1382 6.92604 15.1515C6.98666 15.1651 7.06164 15.1955 7.21162 15.2565C7.99523 15.5747 8.85214 15.75 9.75 15.75ZM6.75 9C6.75 9.20711 6.58211 9.375 6.375 9.375C6.16789 9.375 6 9.20711 6 9C6 8.79289 6.16789 8.625 6.375 8.625C6.58211 8.625 6.75 8.79289 6.75 9ZM10.125 9C10.125 9.20711 9.95711 9.375 9.75 9.375C9.54289 9.375 9.375 9.20711 9.375 9C9.375 8.79289 9.54289 8.625 9.75 8.625C9.95711 8.625 10.125 8.79289 10.125 9ZM13.5 9C13.5 9.20711 13.3321 9.375 13.125 9.375C12.9179 9.375 12.75 9.20711 12.75 9C12.75 8.79289 12.9179 8.625 13.125 8.625C13.3321 8.625 13.5 8.79289 13.5 9Z"
      stroke="black"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MessageIcon;
