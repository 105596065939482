import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const SwitchVerticalIcon = (props: Props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.25 3V15M5.25 15L2.25 12M5.25 15L8.25 12M12.75 15V3M12.75 3L9.75 6M12.75 3L15.75 6"
      stroke="black"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SwitchVerticalIcon;
