import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const MinusIcon = (props: Props) => (
  <svg
    width={8}
    height={2}
    viewBox="0 0 8 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={0.388855} width={8} height={1} fill="black" />
  </svg>
);

export default MinusIcon;
