import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Stack, TextField, TextFieldProps, Typography, useTheme } from '@mui/material';

interface TextareaWithMaxLengthProps extends Omit<TextFieldProps<'outlined'>, 'variant'> {
  isRHF?: boolean;
  maxLength: number;
}

const TextareaWithMaxLength = ({ isRHF, maxLength, ...props }: TextareaWithMaxLengthProps) => {
  const theme = useTheme();
  const formContext = useFormContext();
  const { t } = useTranslation();
  const { control, watch } = formContext || {};

  const currentLength =
    isRHF && formContext && props.name
      ? watch(props.name).length
      : (props.value as string)?.length || 0;

  return (
    <Stack position="relative">
      {isRHF && formContext ? (
        <Controller
          name={props.name || ''}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              {...field}
              error={!!error}
              value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
              helperText={error ? error?.message : props.helperText}
              variant="outlined"
              multiline
              placeholder={t('accountOps.withdrawal.enterContent', {
                value: maxLength.toLocaleString(),
              })}
              rows={6}
              inputProps={{
                maxLength: maxLength,
              }}
              {...props}
            />
          )}
        />
      ) : (
        <StyledTextField
          variant="outlined"
          multiline
          placeholder={t('accountOps.withdrawal.enterContent', {
            value: maxLength.toLocaleString(),
          })}
          rows={6}
          inputProps={{
            maxLength: maxLength,
          }}
          {...props}
        />
      )}

      <Typography
        fontSize={14}
        lineHeight="150%"
        fontWeight={600}
        position="absolute"
        color={theme.palette.grey[300]}
        right={16}
        top={103}
      >
        {`${currentLength.toLocaleString()} / ${maxLength.toLocaleString()}`}
      </Typography>
    </Stack>
  );
};

export default TextareaWithMaxLength;

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    padding: 0,
  },

  '& textarea': {
    padding: '16px',
    height: `108px !important`,
  },
});
