import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
  disabled?: boolean;
}

const KakaoCircleIcon = ({ disabled, ...props }: IProps) => {
  const disabledBackgroundColor = '#E1E1E1';
  const disabledIconColor = '#fff';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="54"
      fill="none"
      viewBox="0 0 55 54"
      {...props}
    >
      <circle cx="27.5" cy="27" r="27" fill={disabled ? disabledBackgroundColor : '#FFE600'} />
      <path
        fill={disabled ? disabledIconColor : '#3C1E1E'}
        d="M27.986 17.358c-6.13 0-11.093 4.06-11.093 9.07 0 3.22 2.06 6.049 5.148 7.659l-1.049 4.038a.35.35 0 0 0 .082.347.3.3 0 0 0 .223.092c.067 0 .134-.03.194-.077l4.494-3.144q.98.152 1.993.154c6.124 0 11.093-4.06 11.093-9.077s-4.962-9.062-11.085-9.062"
        clipPath="url(#a)"
      />
    </svg>
  );
};

export default KakaoCircleIcon;
