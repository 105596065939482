import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {}

const AlarmIcon2 = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="#fff"
        d="M9.354 21c.706.622 1.632 1 2.646 1s1.94-.378 2.646-1M2.294 5.82A4.01 4.01 0 0 1 4.326 2.3m17.376 3.52A4.01 4.01 0 0 0 19.67 2.3M18 8A6 6 0 1 0 6 8c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.088 1.936.014.182.053.252.2.36.133.099.73.099 1.927.099h13.222c1.197 0 1.795 0 1.927-.098.147-.11.186-.179.2-.361.014-.165-.353-.755-1.087-1.936C18.78 13.206 18 11.09 18 8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
    </svg>
  );
};

export default AlarmIcon2;
