import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { alpha, Box, Stack, StackProps, useTheme } from '@mui/material';
import { useStores } from 'src/models';
import FileUpload from 'src/screens/my-page/customer-service/inquiry/FileUpload';

import ImageSlideDialog, { ImageSlideDialogProps } from './dialog/ImageSlideDialog';

interface ImgUploadProps extends StackProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  attachImgPathList: string[];
  setAttachImgPathList: React.Dispatch<React.SetStateAction<string[]>>;
  fileMaxLength: number;
}

function ImgUpload({
  files,
  setFiles,
  attachImgPathList,
  setAttachImgPathList,
  fileMaxLength,
}: ImgUploadProps) {
  const { REACT_APP_API_URL } = process.env;
  const theme = useTheme();
  const { t } = useTranslation();
  const rootStore = useStores();
  const { alertStore } = rootStore;

  const handleRemove = (file: File) => {
    const newFiles = files.filter((_file) => _file !== file);
    setFiles(newFiles);
  };

  const onRemove = (src: string) => {
    const newFiles = attachImgPathList.filter((_file) => _file !== src);
    setAttachImgPathList(newFiles);
  };

  const handleDropFile = <T extends File>(acceptedFiles: T[]) => {
    if (files.length + attachImgPathList.length + acceptedFiles.length > fileMaxLength) {
      alertStore.setProps({
        hasCancelButton: false,
        open: true,
        title: '',
        content: t('myPage.csCenter.inquiries.imgExceed', {
          value: fileMaxLength - (files.length + attachImgPathList.length),
          limit: fileMaxLength,
        }),
      });
      acceptedFiles
        .slice(0, fileMaxLength - (files.length + attachImgPathList.length))
        .map((r) => setFiles((files: File[]) => [...files, r]));
    } else {
      acceptedFiles.map((r) => setFiles((files: File[]) => [...files, r]));
    }
  };

  const [imageSlideDialogProps, setImageSlideDialogProps] = useState<ImageSlideDialogProps>({
    open: false,
  });

  return (
    <>
      <Stack
        direction={'row'}
        display={'-webkit-box'}
        gap={1}
        overflow={'auto'}
        pb={3}
        sx={{
          '&::-webkit-scrollbar': {
            height: 6,
            background: 'transparent',
            backgroundColor: theme.palette.background.neutral,
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-track': {
            opacity: 0,
          },
        }}
      >
        {attachImgPathList.map((src: string, i: number) => {
          return (
            <Box
              key={'img' + i}
              width={105}
              height={105}
              borderRadius={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: theme.palette.background.neutral,
                '& img': { objectFit: 'cover', width: '100%', height: '100%' },
              }}
            >
              <img
                src={src.includes('http') ? src : REACT_APP_API_URL + src}
                alt={src}
                onClick={() => {
                  setImageSlideDialogProps({
                    open: true,
                    initialSlide: i,
                  });
                }}
              />
              <Box
                position={'absolute'}
                top={0}
                right={0}
                width={30}
                height={30}
                borderRadius={'0 0 0 8px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  cursor: 'pointer',
                  bgcolor: alpha(theme.palette.grey[900], 0.72),
                  color: 'white',
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  },
                }}
                onClick={() => onRemove(src)}
              >
                <Close sx={{ width: 20 }} />
              </Box>
            </Box>
          );
        })}
        {files.map((file: File, i: number) => {
          const fileURL = URL.createObjectURL(file);
          if (i > 3) {
            alertStore.setProps({
              hasCancelButton: false,
              open: true,
              title: '',
              content: t('myPage.csCenter.inquiries.imgLimit', { limit: fileMaxLength }),
            });
            return null;
          }
          return (
            <Box
              key={'img' + i}
              width={105}
              height={105}
              borderRadius={1}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: theme.palette.background.neutral,
                '& img': { objectFit: 'cover', width: '100%', height: '100%' },
              }}
            >
              <img
                src={fileURL}
                alt={file.name}
                onClick={() => {
                  setImageSlideDialogProps({
                    open: true,
                    initialSlide: attachImgPathList.length + i,
                  });
                }}
              />
              <Box
                position={'absolute'}
                top={0}
                right={0}
                width={30}
                height={30}
                borderRadius={'0 0 0 8px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  cursor: 'pointer',
                  bgcolor: alpha(theme.palette.grey[900], 0.72),
                  color: 'white',
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  },
                }}
                onClick={() => handleRemove(file)}
              >
                <Close sx={{ width: 20 }} />
              </Box>
            </Box>
          );
        })}

        {attachImgPathList.length + files.length < fileMaxLength && (
          <Box
            width={105}
            height={105}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ position: 'relative', overflow: 'hidden' }}
          >
            <FileUpload
              files={files}
              showPreview={true}
              onDrop={handleDropFile}
              showFileIcon={false}
              accept={{ 'image/jpeg': [], 'image/jpg': [], 'image/png': [] }}
            />
          </Box>
        )}
      </Stack>

      {imageSlideDialogProps.open && (
        <ImageSlideDialog
          {...imageSlideDialogProps}
          onClose={() => setImageSlideDialogProps({ open: false })}
          imageList={attachImgPathList.concat(
            files.map((file: File) => {
              const imgUrl = URL.createObjectURL(file);
              return imgUrl;
            }),
          )}
        />
      )}
    </>
  );
}

export default ImgUpload;
