import { Buffer } from 'buffer';
import { IResult } from 'src/@types/apiResponse';

import { TApiErrorResponseData, TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/common/v1/user/labtest';

export type ResultSearchParams = {
  orderStateCd?: number;
};

/**
 * 검사 결과 목록 조회
 */
export const getResults = async (params?: ResultSearchParams) => {
  try {
    const url = `${SUB_URL}`;
    const response = await instance.get<TApiResponseData<IResult[]>>(url, {
      params,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 검사 결과 상세 조회
 */
export const getResultDetail = async (orderGoodsNo: string) => {
  try {
    const url = `${SUB_URL}/${orderGoodsNo}`;
    const response = await instance.get<TApiResponseData<IResult>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 검사 결과 PDF 다운로드
 */
export const getPdf = async (orderGoodsNo: string) => {
  try {
    const url = `${SUB_URL}/${orderGoodsNo}/pdf`;
    const response = await instance.get<TApiResponseData<any>>(url, {
      responseType: 'arraybuffer',
    });
    return response;
  } catch (error: any) {
    if (error.response) {
      const { data, headers } = error.response;

      if (headers['content-type']?.includes('application/json')) {
        const jsonText = Buffer.from(data).toString('utf-8');
        const jsonData = JSON.parse(jsonText) as TApiErrorResponseData;
        throw new Error(jsonData.errorMessage);
      }
    }
    console.error(error);
  }
};
