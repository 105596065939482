import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const PlusIcon = (props: Props) => (
  <svg
    width={8}
    height={9}
    viewBox="0 0 8 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y={4.38885} width={8} height={1} fill="black" />
    <rect x={3.5} y={0.888855} width={1} height={8} fill="black" />
  </svg>
);

export default PlusIcon;
