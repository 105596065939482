import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
  disabled?: boolean;
}

const AppleCircleIcon = ({ disabled, ...props }: IProps) => {
  const disabledBackgroundColor = '#E1E1E1';
  const disabledIconColor = '#fff';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="54"
      fill="none"
      viewBox="0 0 55 54"
      {...props}
    >
      <circle cx="27.5" cy="27" r="27" fill={disabled ? disabledBackgroundColor : '#202123'} />
      <path
        fill={disabled ? disabledIconColor : '#fff'}
        d="M30.671 17.007c.826-1.076 1.388-2.522 1.241-3.997-1.212.062-2.691.832-3.543 1.91-.769.92-1.45 2.427-1.272 3.841 1.36.121 2.717-.708 3.574-1.754m1.225 2.028c-1.975-.121-3.653 1.165-4.597 1.165s-2.39-1.1-3.95-1.074c-2.033.03-3.918 1.226-4.95 3.126-2.124 3.802-.56 9.44 1.505 12.536 1.001 1.532 2.212 3.217 3.797 3.158 1.505-.062 2.093-1.014 3.918-1.014s2.357 1.014 3.95.987c1.65-.032 2.68-1.532 3.685-3.067 1.146-1.75 1.618-3.432 1.647-3.525-.029-.03-3.178-1.288-3.211-5.058-.029-3.158 2.477-4.656 2.594-4.75-1.415-2.176-3.627-2.42-4.39-2.482"
        clipPath="url(#a)"
      />
    </svg>
  );
};

export default AppleCircleIcon;
