import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'; // useTranslation import 추가
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, styled, Typography, useTheme } from '@mui/material';
import { TOrderInquiryFormData } from 'src/@types';
import { IOrderGoods } from 'src/@types/apiResponse';
import { INQUIRY_TYPE_CODE } from 'src/assets/static/constants';
import { useStores } from 'src/models';
import { SUB_LINK } from 'src/routes/paths';
import { orderApi } from 'src/services';
import * as Yup from 'yup';

import { RHFTextField } from '../hook-form';
import FormProvider from '../hook-form/FormProvider';
import ImgUpload from '../ImgUpload';
import SlideFullScreenDialog, { SlideFullScreenDialogProps } from '../SlideFullScreenDialog';
import SuccessScreen from '../SuccessScreen';
import TextareaWithMaxLength from '../TextareaWithMaxLength';

import GoodsInfoView from './GoodsInfoView';

export interface OrderInquiryFormDialogProps extends SlideFullScreenDialogProps {
  orderNo?: string;
  goods?: IOrderGoods;
  onSuccess?: () => void;
  onFail?: (message: string) => void;
  formData?: TOrderInquiryFormData;
  inquirySid?: number;
}

function OrderInquiryFormDialog({
  orderNo,
  goods,
  onSuccess,
  formData,
  inquirySid,
  ...props
}: OrderInquiryFormDialogProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { snackbarStore } = useStores();
  const [isSuccess, setIsSuccess] = useState(false);
  const resolver = yupResolver(
    Yup.object().shape({
      inquiryNm: Yup.string().required(t('form.orderInquiryForm.inquiryNmRequired')),
      inquiryConts: Yup.string().required(t('form.orderInquiryForm.inquiryContsRequired')),
      uploadedFiles: Yup.array().max(5, t('form.orderInquiryForm.uploadedFilesMax')),
    }),
  );

  const theme = useTheme();
  const formId = 'orderInquiryForm';
  const [files, setFiles] = useState<File[]>([]);
  const [attachImgPathList, setAttachImgPathList] = useState<string[]>(
    formData?.attachImgPathList || [],
  );
  const methods = useForm<TOrderInquiryFormData>({
    defaultValues: {
      inquiryNm: '',
      inquiryConts: '',
      inquiryTypeCd: INQUIRY_TYPE_CODE.GOODS,
      uploadFiles: [],
      attachImgPathList: [],
      orderGoodsNoList: [goods?.orderGoodsNo],
    },
    resolver: resolver,
  });

  useEffect(() => {
    // init when open dialog
    if (props.open) {
      setAttachImgPathList(formData?.attachImgPathList || []);
      setFiles([]);
      methods.reset({
        inquiryNm: formData?.inquiryNm || '',
        inquiryConts: formData?.inquiryConts || '',
        inquiryTypeCd: INQUIRY_TYPE_CODE.GOODS,
        uploadFiles: [],
        attachImgPathList: formData?.attachImgPathList || [],
        orderGoodsNoList: formData?.orderGoodsNoList || [],
      });
    }
  }, [methods, props.open, formData]);

  const callSubmitApi = async (data: TOrderInquiryFormData) => {
    if (!orderNo) return;

    try {
      if (formData && inquirySid) {
        // 수정
        const result = await orderApi.updateOrderInquiry(orderNo, inquirySid, {
          ...data,
          uploadFiles: files,
          attachImgPathList: attachImgPathList,
        });
        return result;
      } else {
        // 등록
        const result = await orderApi.createOrderInquiry(orderNo, {
          ...data,
          uploadFiles: files,
        });
        return result;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // submit form
  const handleSubmit = async (data: TOrderInquiryFormData) => {
    try {
      // submit api call
      const result = await callSubmitApi(data);

      // success
      if (result?.success) {
        onSuccess && onSuccess();
        setIsSuccess(true);
        return;
      }

      // fail
      if (result?.message) {
        snackbarStore.setProps({
          open: true,
          title: result.message,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SlideFullScreenDialog
      headerProps={{
        centerTitle: t('orderInquiry.title'),
        rightTypes: ['cart'],
        leftTypes: ['back'],
        onClickLeft: () => props.onClose?.(),
      }}
      {...props}
    >
      {isSuccess ? (
        <SuccessScreen
          title={t('orderInquiry.submitted')}
          description={t('orderInquiry.submittedDescription')}
          buttonLabel={t('orderInquiry.viewOrderInquiries')}
          onClick={() => navigate(SUB_LINK.orderInquiry.root)}
        />
      ) : (
        <Container>
          {goods && (
            <Item sx={{ paddingTop: 5 }}>
              <GoodsInfoView type="simple" goods={goods} imgSize={80} />
            </Item>
          )}
          <FormProvider
            methods={methods}
            formId={formId}
            onSubmit={methods.handleSubmit(handleSubmit)}
          >
            <Item>
              <Stack flexDirection="column" gap={2.5}>
                <Typography fontSize={16} lineHeight="150%" fontWeight={600}>
                  {t('orderInquiry.inquiryTitle')}
                </Typography>
                <RHFTextField
                  name="inquiryNm"
                  variant="outlined"
                  placeholder={t('orderInquiry.inquiryTitlePlaceholder')}
                />
              </Stack>
              <Stack flexDirection="column" gap={2.5}>
                <Typography fontSize={16} lineHeight="150%" fontWeight={600}>
                  {t('orderInquiry.inquiryContent')}
                </Typography>
                <TextareaWithMaxLength
                  isRHF
                  name="inquiryConts"
                  maxLength={1500}
                  placeholder={t('orderInquiry.inquiryContentPlaceholder')}
                />
              </Stack>
              <Stack flexDirection="column" gap={2.5}>
                <Typography fontSize={16} lineHeight="150%" fontWeight={600}>
                  {t('orderInquiry.attachPhoto')}
                </Typography>
                <ImgUpload
                  files={files}
                  setFiles={setFiles}
                  attachImgPathList={attachImgPathList}
                  setAttachImgPathList={setAttachImgPathList}
                  fileMaxLength={5}
                />
              </Stack>
              <Button sx={{ marginTop: 5 }} type="submit" form={formId}>
                {t('orderInquiry.submit')}
              </Button>
            </Item>
          </FormProvider>
        </Container>
      )}
    </SlideFullScreenDialog>
  );
}

export default OrderInquiryFormDialog;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
  backgroundColor: theme.palette.grey[100],
}));

const Item = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(10, 5),
  gap: theme.spacing(5),
  backgroundColor: theme.palette.background.paper,
}));
