import React from 'react';
import { Button, Stack, StackProps, styled, Typography, useTheme } from '@mui/material';
import { SuccessIcon } from 'src/assets/icons';

interface SuccessScreenProps extends StackProps {
  title?: string;
  description?: string;
  buttonLabel?: string;
  onClick?: () => void;
}

function SuccessScreen({ title, description, buttonLabel, onClick, ...props }: SuccessScreenProps) {
  const theme = useTheme();
  const titleLines = title?.split('<br/>');
  const descriptionLines = description?.split('<br/>');

  return (
    <Container {...props}>
      <Stack flexGrow={1} justifyContent="center" alignItems="center" gap={10}>
        <SuccessIcon />
        <Stack maxWidth={250} textAlign="center" flexDirection="column" gap={5}>
          {title && (
            <Typography fontSize={24} lineHeight="150%" fontWeight={700}>
              {titleLines?.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < titleLines.length - 1 && <br />}
                </React.Fragment>
              ))}
            </Typography>
          )}
          {description && (
            <Typography
              fontSize={16}
              lineHeight="150%"
              textAlign="center"
              color={theme.palette.grey[400]}
            >
              {descriptionLines?.map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  {index < descriptionLines.length - 1 && <br />}
                </React.Fragment>
              ))}
            </Typography>
          )}
        </Stack>
      </Stack>
      {buttonLabel && <Button onClick={onClick}>{buttonLabel}</Button>}
    </Container>
  );
}

export default SuccessScreen;

const Container = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(5),
  height: '100%',
}));
