import { TDeliveryAddressFormData } from 'src/@types';
import { IDeliveryAddress } from 'src/@types/apiResponse';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/common/v1/dlivry/addr';

/**
 * 배송지 목록조회
 */
export const getDeliveryAddresses = async () => {
  try {
    const url = `${SUB_URL}`;
    const response = await instance.get<TApiResponseData<IDeliveryAddress[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 기본 배송지 조회
 */
export const getDefaultDeliveryAddress = async () => {
  try {
    const url = `${SUB_URL}/default`;
    const response = await instance.get<TApiResponseData<IDeliveryAddress>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 배송지 등록
 */
export const createDeliveryAddress = async (payload: TDeliveryAddressFormData) => {
  try {
    const url = `${SUB_URL}`;
    const response = await instance.post<TApiResponseData<IDeliveryAddress>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 배송지 수정
 */
export const updateDeliveryAddress = async (
  dlivryAddrSid: number,
  payload: TDeliveryAddressFormData,
) => {
  try {
    const url = `${SUB_URL}/${dlivryAddrSid}`;
    const response = await instance.put<TApiResponseData<IDeliveryAddress>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 배송지 삭제
 */
export const deleteDeliveryAddress = async (dlivryAddrSid: number) => {
  try {
    const url = `${SUB_URL}/${dlivryAddrSid}`;
    const response = await instance.delete<TApiResponseData<IDeliveryAddress>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
