import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
  disabled?: boolean;
}

const GoogleCircleIcon = ({ disabled, ...props }: IProps) => {
  const disabledBackgroundColor = '#E1E1E1';
  const disabledIconColor = '#fff';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="54"
      fill="none"
      viewBox="0 0 55 54"
      {...props}
    >
      <circle cx="27.5" cy="27" r="27" fill={disabled ? disabledBackgroundColor : '#FAFAFA'} />
      <mask
        id="a"
        width="12"
        height="12"
        x="28"
        y="24"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
      >
        <path
          fill="#fff"
          d="M39.28 26.833q-.002-1.25-.214-2.402h-11.06v4.543h6.32a5.4 5.4 0 0 1-2.344 3.544v2.946h3.795c2.22-2.045 3.502-5.055 3.502-8.632z"
        />
      </mask>
      <g mask="url(#a)">
        <path
          fill={disabled ? disabledIconColor : '#3E82F1'}
          d="M45.804 17.907H21.481v24.082h24.323z"
        />
      </g>
      <mask
        id="b"
        width="19"
        height="11"
        x="17"
        y="28"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
      >
        <path
          fill="#fff"
          d="M28.006 38.31c3.17 0 5.83-1.051 7.772-2.846l-3.795-2.946c-1.05.704-2.397 1.122-3.976 1.122-3.058 0-5.648-2.066-6.572-4.843h-3.924v3.043a11.74 11.74 0 0 0 10.495 6.47"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill={disabled ? disabledIconColor : '#32A753'}
          d="M42.303 22.273H10.987v22.562h31.316z"
        />
      </g>
      <mask
        id="c"
        width="6"
        height="11"
        x="16"
        y="21"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
      >
        <path
          fill="#fff"
          d="M21.434 28.797a7 7 0 0 1-.368-2.231c0-.775.133-1.527.368-2.232v-3.042H17.51a11.74 11.74 0 0 0-1.25 5.274c0 1.895.454 3.688 1.25 5.274z"
        />
      </mask>
      <g mask="url(#c)">
        <path
          fill={disabled ? disabledIconColor : '#F9BB00'}
          d="M27.959 14.768H9.737v23.597H27.96z"
        />
      </g>
      <mask
        id="d"
        width="19"
        height="11"
        x="17"
        y="14"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'luminance' }}
      >
        <path
          fill="#fff"
          d="M28.006 19.493c1.724 0 3.272.592 4.489 1.756l3.368-3.368c-2.034-1.895-4.692-3.059-7.857-3.059a11.74 11.74 0 0 0-10.495 6.47l3.924 3.043c.924-2.775 3.512-4.843 6.572-4.843z"
        />
      </mask>
      <g mask="url(#d)">
        <path
          fill={disabled ? disabledIconColor : '#E74133'}
          d="M42.388 8.298H10.987V30.86h31.4z"
        />
      </g>
    </svg>
  );
};

export default GoogleCircleIcon;
