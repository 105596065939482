import React from 'react';
import { useTranslation } from 'react-i18next'; // 번역 훅 추가
import { Divider, Stack, StackProps, styled, Typography, useTheme } from '@mui/material';
import { IGoods, IOrderGoods } from 'src/@types/apiResponse';
import { GOODS_STOCK_EXTRA, SALE_STATE_CODE } from 'src/assets/static/constants';
import Image from 'src/components/image';

import OrderStatusLabel from './OrderStatusLabel';

interface GoodsInfoViewProps extends StackProps {
  type: 'cart' | 'order' | 'simple' | 'market' | 'marketRow';
  imgSize?: number;
  lines?: number;
  goods: IGoods | IOrderGoods;
  // 가격 표기가 필요한 경우 default로 true
  needGoodsAmt?: boolean;
  // 주문 상태 레이블이 필요한 경우 default로 false
  needOrderStatusLabel?: boolean;
}

/**
 * - 상품 정보를 보여주는 컴포넌트로 type에 따라 다른 레이아웃으로 보여줍니다.
 */
function GoodsInfoView({
  type,
  goods,
  imgSize = 110,
  lines = 2,
  needGoodsAmt = true,
  needOrderStatusLabel = false,
  ...props
}: GoodsInfoViewProps) {
  const theme = useTheme();
  const { t } = useTranslation(); // 번역 훅 사용
  const thumbnailPath =
    typeof goods.thumbnlPath === 'string' ? goods.thumbnlPath : goods.thumbnlPath[0];
  const goodsThumbnailSrc = thumbnailPath?.includes('https')
    ? thumbnailPath
    : `${process.env.REACT_APP_API_URL}${thumbnailPath}`;

  // IOrderGoods인지 타입을 체크하는 함수
  const isOrderGoods = (goods: IGoods | IOrderGoods): goods is IOrderGoods => {
    return (goods as IOrderGoods).orderStateCd !== undefined;
  };

  // 판매중인 상품이 아니거나 상품이 품절인 경우 overlay를 보여주기 위한 render 함수
  const renderOverlay = () => {
    const isNotOnSale = goods.saleStateCd && goods.saleStateCd?.code !== SALE_STATE_CODE.ON_SALE;
    const isSoldOut = goods.stockCnt - GOODS_STOCK_EXTRA <= 0;

    if (!isSoldOut && !isNotOnSale) return null;

    const overlayText = isNotOnSale ? t('cart.discontinued') : t('cart.soldOut');

    return (
      <GoodsImgOverlay>
        <Typography
          fontSize={16}
          lineHeight={'150%'}
          fontWeight={600}
          color={theme.palette.grey[300]}
        >
          {overlayText}
        </Typography>
      </GoodsImgOverlay>
    );
  };

  switch (type) {
    case 'order':
      return (
        <RowContainer {...props}>
          <GoodsImg imgSize={imgSize} src={goodsThumbnailSrc} sx={{ flexShrink: 0 }} />
          <ColumnContainer gap={1.5}>
            {/* goods의 타입이 IOrderGoods인 경우에만 OrderStatusLabel 컴포넌트 보이도록 처리 */}
            {needOrderStatusLabel && isOrderGoods(goods) && <OrderStatusLabel goods={goods} />}
            <GoodsName lines={lines}>{goods.goodsNm}</GoodsName>
            <Stack
              flexDirection="row"
              alignItems="center"
              gap={1.5}
              sx={{ fontSize: 13, lineHeight: '150%', color: theme.palette.grey[500] }}
            >
              <span>{t('goods.quantity')}</span>
              <Divider flexItem orientation="vertical" variant="middle" />
              <span>
                {t('goods.pieces', { count: goods.cnt || (goods as IOrderGoods).purchsCnt || 0 })}
              </span>
            </Stack>
            {needGoodsAmt && (
              <Typography fontSize={16} lineHeight={'150%'} fontWeight={600}>
                {(goods as IOrderGoods)?.totPaymentAmt &&
                  `${(goods as IOrderGoods)?.totPaymentAmt.toLocaleString()}${t('currency')}`}
              </Typography>
            )}
          </ColumnContainer>
        </RowContainer>
      );
    case 'simple':
      return (
        <RowContainer {...props}>
          <GoodsImg imgSize={imgSize} src={goodsThumbnailSrc} sx={{ flexShrink: 0 }} />
          <ColumnContainer gap={1.5}>
            <GoodsName lines={lines}>{goods.goodsNm}</GoodsName>
            <Typography fontSize={16} lineHeight={'150%'} fontWeight={600}>
              {goods.goodsAmt && `${goods.goodsAmt.toLocaleString()}` + t('currency')}
            </Typography>
          </ColumnContainer>
        </RowContainer>
      );
    case 'cart':
      return (
        <RowContainer {...props}>
          <Stack position="relative" sx={{ flexShrink: 0 }}>
            <GoodsImg imgSize={imgSize} src={goodsThumbnailSrc} sx={{ flexShrink: 0 }} />
            {renderOverlay()}
          </Stack>
          <ColumnContainer gap={2}>
            <GoodsName lines={lines}>{goods.goodsNm}</GoodsName>
            <Stack flexDirection="row" alignItems="center" gap={1}>
              <Typography fontSize={16} lineHeight={'150%'} fontWeight="600">
                {`${goods?.goodsAmt.toLocaleString()}${t('currency')}`}
              </Typography>
              {goods.dispDscntRate !== 0 && (
                <Typography
                  fontSize={16}
                  lineHeight={'150%'}
                  style={{ textDecoration: 'line-through' }}
                  color={theme.palette.grey[400]}
                >{`${goods.price.toLocaleString()}${t('currency')}`}</Typography>
              )}
            </Stack>
          </ColumnContainer>
        </RowContainer>
      );
    case 'market':
      return (
        <ColumnContainer gap={5} {...props}>
          <div
            style={{ width: '100%', paddingTop: '100%', position: 'relative', overflow: 'hidden' }}
          >
            <GoodsImg
              src={goodsThumbnailSrc}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
              }}
            />
          </div>
          <Stack flexDirection="column" gap={3}>
            <GoodsName>{goods.goodsNm}</GoodsName>
            <Stack flexDirection="column" gap={2}>
              {goods.dispDscntRate !== 0 && (
                <OriginPrice>{`${goods.price.toLocaleString()}${t('currency')}`}</OriginPrice>
              )}
              <Stack flexDirection="row" alignItems="center" gap={1}>
                {goods.dispDscntRate !== 0 && (
                  <SalePercent>{`${goods.dispDscntRate}%`}</SalePercent>
                )}
                <SalePrice>
                  {goods.goodsAmt && `${goods.goodsAmt.toLocaleString()}${t('currency')}`}
                </SalePrice>
              </Stack>
            </Stack>
          </Stack>
        </ColumnContainer>
      );
    case 'marketRow':
      return (
        <RowContainer {...props}>
          <GoodsImg imgSize={imgSize} src={goodsThumbnailSrc} sx={{ flexShrink: 0 }} />
          <ColumnContainer gap={3}>
            <GoodsName lines={lines}>{goods.goodsNm}</GoodsName>
            <Stack gap={2}>
              {goods.dispDscntRate !== 0 && (
                <Typography
                  fontSize={16}
                  lineHeight={'100%'}
                  style={{ textDecoration: 'line-through' }}
                  color={theme.palette.grey[400]}
                >{`${goods.price.toLocaleString()}${t('currency')}`}</Typography>
              )}
              <Stack flexDirection="row" alignItems="center" gap={1}>
                {goods.dispDscntRate !== 0 && (
                  <Typography
                    fontSize={18}
                    lineHeight={'100%'}
                    fontWeight="600"
                    color={theme.palette.error.main}
                  >{`${goods.dispDscntRate}%`}</Typography>
                )}
                <Typography fontSize={18} lineHeight={'100%'} fontWeight="600">
                  {`${goods?.goodsAmt.toLocaleString()}${t('currency')}`}
                </Typography>
              </Stack>
            </Stack>
          </ColumnContainer>
        </RowContainer>
      );
    default:
      return <></>;
  }
}

export default GoodsInfoView;

const GoodsImg = styled(Image, { shouldForwardProp: (props) => props !== 'imgSize' })<{
  imgSize?: number;
}>(({ theme, imgSize = 110 }) => ({
  width: imgSize,
  height: imgSize,
  objectFit: 'cover',
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
  flexShrink: 0,
}));

const RowContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.spacing(5),
}));

const ColumnContainer = styled(Stack)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const GoodsName = styled(Typography, { shouldForwardProp: (props) => props !== 'lines' })<{
  lines?: number;
}>(({ theme, lines = 2 }) => ({
  width: '100%',
  display: '-webkit-box',
  WebkitLineClamp: lines,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  wordWrap: 'break-word',
  fontSize: 16,
  lineHeight: '150%',

  ...(lines === 1 && {
    lineHeight: '100%',
  }),
}));

const OriginPrice = styled(Typography)(({ theme }) => ({
  textDecoration: 'line-through',
  fontSize: 16,
  lineHeight: '100%',
  color: theme.palette.grey[400],
}));

const SalePrice = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '100%',
  color: theme.palette.common.black,

  '& strong': {
    color: theme.palette.error.main,
    marginRight: theme.spacing(2),
  },
}));

const SalePercent = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '100%',
  color: theme.palette.error.main,
}));

const GoodsImgOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: theme.spacing(2),
}));
