import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }

  interface Palette {
    gradient: {
      primary1: string;
      secondary1: string;
      primary2: string;
      secondary2: string;
      style1: string;
      style2: string;
    };
  }
}

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F6F6F8',
  200: '#F4F6F8',
  300: '#DDDDDD',
  400: '#AAAAAA',
  500: '#888888',
  600: '#666666',
  700: '#454F5B',
  800: '#202123',
  900: '#161C24',
};

const PRIMARY =
  process.env.REACT_APP_APP_ID === '1'
    ? {
        lighter: '#FBD3E1',
        light: '#F4A5C1',
        main: '#EB7CA4',
        dark: '#E05989',
        darker: '#C53669',
        contrastText: '#fff',
      }
    : {
        lighter: '#77E3CE',
        light: '#4AD4B9',
        main: '#27C8A7',
        dark: '#02BD98',
        darker: '#008E72',
        contrastText: '#fff',
      };

const SECONDARY = {
  lighter: '#9C99AF',
  light: '#737085',
  main: '#000000',
  dark: '#403E51',
  darker: '#1F1E2F',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: '#fff',
};

const ERROR = {
  lighter: '#FFB7BE',
  light: '#FF827E',
  main: '#F93D40',
  dark: '#FF1400',
  darker: '#F00007',
  contrastText: '#fff',
};

// gradient color set
const GRADIENT =
  process.env.REACT_APP_APP_ID === '1'
    ? {
        gradient: {
          primary1: '#F0ABDF',
          secondary1: '#F6D9BB',
          primary2: '#EB7CA4',
          secondary2: '#F6D9BB',
          style1: 'linear-gradient(165.24deg, #F0ABDF 46.73%, #F6D9BB 106.41%)',
          style2: 'linear-gradient(150deg, #EB7CA4 43.76%, #F6D9BB 108.04%)',
        },
      }
    : {
        gradient: {
          primary1: '#5BC9B0',
          secondary1: '#93EC9F',
          primary2: '#5BC9B0',
          secondary2: '#FCE61F',
          style1: 'linear-gradient(131.03deg, #5BC9B0 48.26%, #93EC9F 106.29%)',
          style2: 'linear-gradient(155.44deg, #5BC9B0 43.67%, #FCE61F 133.99%)',
        },
      };

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: GREY[300],
  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  ...GRADIENT,
};

export default function palette(themeMode: 'light' | 'dark') {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  } as const;

  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  } as const;

  return themeMode === 'light' ? light : dark;
}
