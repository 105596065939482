import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
  disabled?: boolean;
}

const NaverCircleIcon = ({ disabled, ...props }: IProps) => {
  const disabledBackgroundColor = '#E1E1E1';
  const disabledIconColor = '#fff';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="54"
      fill="none"
      viewBox="0 0 55 54"
      {...props}
    >
      <circle cx="27.5" cy="27" r="27" fill={disabled ? disabledBackgroundColor : '#4AB749'} />
      <path
        fill={disabled ? disabledIconColor : '#fff'}
        d="M17.857 18.343c.012 5.769 0 11.543 0 17.312 2.201.011 4.408.011 6.608 0 .034-2.862-.04-5.724.04-8.586.72.99 1.384 2.027 2.122 3.007 1.23 1.882 2.626 3.652 3.811 5.563 2.218.055 4.436 0 6.654.033.023-.273.045-.54.045-.813-.034-5.507.029-11.02-.028-16.528-2.195-.016-4.385 0-6.58-.01-.017 2.878.017 5.757-.017 8.63-2.036-2.84-3.96-5.768-6.047-8.58-2.195-.095-4.407-.028-6.608-.028"
        clipPath="url(#a)"
      />
    </svg>
  );
};

export default NaverCircleIcon;
