import React, { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { DialogContent, DialogProps, Fade, styled, useTheme } from '@mui/material';
import { MOBILE_MAX_WIDTH } from 'src/assets/static/constants';
import { Header, HeaderProps } from 'src/layouts/header';

import PopperDialog from './dialog/PopperDialog';

// AnimatedDialogContent를 정의하여 Animated 컴포넌트로 감싸기
const AnimatedDialogContent = animated(DialogContent);

export interface SlideFullScreenDialogProps extends DialogProps {
  onClose?: () => void;
  children?: React.ReactNode;
  headerProps?: HeaderProps;
}

function SlideFullScreenDialog({
  open,
  onClose,
  children,
  headerProps,
  ...other
}: SlideFullScreenDialogProps) {
  const theme = useTheme();
  // isSlide가 true이면 슬라이드 애니메이션, 아니면 Fade 애니메이션 사용
  const isSlide = window.innerWidth <= MOBILE_MAX_WIDTH;

  const blocker = useBlocker(({ currentLocation, nextLocation, historyAction }) => {
    // 뒤로가기 실행 시 dialog가 열려있는 상태라면 뒤로가기를 막는다.
    return currentLocation.pathname !== nextLocation.pathname && historyAction === 'POP' && open;
  });

  // 스프링 애니메이션 설정: 슬라이드로 나타나고 사라짐
  const [styles, api] = useSpring(() => ({
    x: isSlide ? window.innerWidth : 0, // 초기 위치 설정
    config: {
      duration: 200,
    },
  }));

  useEffect(() => {
    const touchStart = (ev: TouchEvent): void => {
      if (ev.touches.length === 1) {
        const touch = ev.touches[0];

        if (touch.clientX < window.innerWidth * 0.05 || touch.clientX > window.innerWidth * 1) {
          ev.preventDefault();
        }
      }
    };

    // Safari defaults to passive: true for the touchstart event, so we need to explicitly specify false
    // See https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
    const options: AddEventListenerOptions = { passive: false };

    if (open) {
      window.addEventListener('touchstart', touchStart, options);
    } else {
      window.removeEventListener('touchstart', touchStart, options);
    }

    return () => window.removeEventListener('touchstart', touchStart, options);
  }, [open]);

  // 모달 열림 시 애니메이션 설정
  useEffect(() => {
    if (open) {
      api.start({ x: 0 });
    } else if (!open && isSlide) {
      api.start({ x: window.innerWidth });
    }
  }, [open, isSlide, api]);

  // 드래그 제스처 핸들러 설정
  const bind = useDrag(
    ({ active, movement: [mx], cancel }) => {
      if (mx < 0) {
        cancel(); // 왼쪽으로 드래그하는 것을 막기
      }
      const shouldClose = mx > window.innerWidth / 3 && !active;
      if (shouldClose) {
        api.start({
          x: window.innerWidth,
          onRest: () => {
            // 애니메이션이 끝난 후 onClose 호출
            onClose?.();
          },
        });
      } else {
        api.start({ x: active ? mx : 0 });
      }
    },
    {
      axis: 'x', // 수평 방향으로만 드래그 제스처를 활성화
      preventDefault: true, // 기본 이벤트 방지
      filterTaps: true, // 탭 이벤트 필터링
    },
  );

  useEffect(() => {
    // dialog가 열려있는 상태에서 뒤로가기를 실행하면 onClose를 호출한다.
    if (blocker.state === 'blocked') {
      onClose && onClose();
      blocker.reset();
    }
  }, [blocker, onClose]);

  return (
    <PopperDialog
      onClose={onClose}
      open={open}
      TransitionComponent={!isSlide ? Fade : undefined}
      transitionDuration={{ enter: 200, exit: 200 }}
      sx={{
        zIndex: 1100,
      }}
      PaperProps={{
        component: AnimatedDialogContent,
        ...(isSlide && bind()),
        style: {
          ...(styles as any), // 스프링 애니메이션 스타일 스프레드
          touchAction: 'pan-y', // 수직 스크롤을 허용하여 수평 제스처와 충돌하지 않도록 설정
          backgroundColor: theme.palette.background.default,
        },
      }}
      {...other}
    >
      <Container>
        {headerProps && <Header {...headerProps} />}
        <ChildContainer>{children}</ChildContainer>
      </Container>
    </PopperDialog>
  );
}

export default SlideFullScreenDialog;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

const ChildContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));
