import { SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {}

const URLCircleIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      fill="none"
      viewBox="0 0 50 50"
      {...props}
    >
      <circle cx="25" cy="25" r="25" fill="#F7F7F7" />
      <path
        fill="#888"
        d="m24.607 16.732 2.187-2.188a6.123 6.123 0 0 1 9.99 1.987 6.125 6.125 0 0 1-1.327 6.676l-4.375 4.375a6.126 6.126 0 0 1-8.663 0 1.312 1.312 0 0 1 1.855-1.855 3.5 3.5 0 0 0 4.953 0l4.375-4.375a3.504 3.504 0 0 0-4.953-4.953l-2.187 2.188a1.313 1.313 0 0 1-1.855-1.855m-8.208 16.87a3.5 3.5 0 0 0 4.953 0l2.187-2.188a1.312 1.312 0 0 1 1.855 1.855l-2.187 2.188a6.13 6.13 0 0 1-6.676 1.327 6.127 6.127 0 0 1-1.987-9.99l4.375-4.375a6.123 6.123 0 0 1 8.663 0 1.313 1.313 0 0 1-1.855 1.855 3.5 3.5 0 0 0-4.953 0L16.4 28.65a3.497 3.497 0 0 0 0 4.953"
      />
    </svg>
  );
};

export default URLCircleIcon;
