import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, BoxProps, Link, styled } from '@mui/material';
// @mui
import { useTheme } from '@mui/material/styles';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const { REACT_APP_APP_ID, REACT_APP_APP_NAME } = process.env;

    const renderLogoImage = () => {
      return REACT_APP_APP_ID === '1' ? (
        <Box
          component="img"
          src={'/logo/Y-Eng.svg'}
          alt="micobalance-Y"
          sx={{ width: 151, ...sx }}
        />
      ) : (
        <Box
          component="img"
          src={'/logo/G-Eng.svg'}
          alt="micobalance-G"
          sx={{ width: 151, ...sx }}
        />
      );
      // <Typography
      //   fontSize={22}
      //   lineHeight="100%"
      //   fontWeight={600}
      //   letterSpacing="-1%"
      //   color={theme.palette.text.primary}
      // >
      //   {REACT_APP_APP_NAME}
      // </Typography>
    };

    if (disabledLink) {
      return renderLogoImage();
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        <Box sx={{ ...sx }} display={'flex'} alignItems={'center'}>
          {renderLogoImage()}
        </Box>
      </Link>
    );
  },
);

export default Logo;

const LogoImage = styled('img')(({ theme }) => ({
  width: 140,
  height: 'auto',
}));
