import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  fillColor?: string;
}

const ArrowDownIcon = ({ fillColor, ...props }: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.12461 9.00002L12.0046 12.88L15.8846 9.00002C16.2746 8.61002 16.9046 8.61002 17.2946 9.00002C17.6846 9.39002 17.6846 10.02 17.2946 10.41L12.7046 15C12.3146 15.39 11.6846 15.39 11.2946 15L6.70461 10.41C6.31461 10.02 6.31461 9.39002 6.70461 9.00002C7.09461 8.62002 7.73461 8.61002 8.12461 9.00002Z"
      fill={fillColor ? fillColor : 'black'}
    />
  </svg>
);

export default ArrowDownIcon;
