import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const GobackIcon = (props: Props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_65_719)">
      <g clipPath="url(#clip1_65_719)">
        <mask
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={24}
          height={24}
        >
          <rect width={24} height={24} fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_65_719)">
          <path
            d="M8 5L1 12L8 19"
            stroke="#202123"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath>
        <rect width={24} height={24} fill="white" />
      </clipPath>
      <clipPath>
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GobackIcon;
